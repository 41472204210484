<form>
  <div mat-dialog-content class="thermal-theme">

    <div fxLayout="row" class="flexwrap" *ngIf="data.equipment.fromProtocol">

      <div fxFlex="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>
            {{ 'protocolEdit.dialog.equipment.type.label' | translate }} {{ 'common.inProtocol' | translate }}
          </mat-label>
          <mat-select required name="idTypeProtocol" [ngModel]="data.equipment.idTypeProtocol" disabled>
            <mat-option *ngFor="let item of equipmentTypes" [value]="item.id">
              {{ 'equipmentType.' + item.translation | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="equipmentProtocol" [(ngModel)]="data.equipment.equipmentProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.equipment.equipment.label' | translate }} {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="makerProtocol" [(ngModel)]="data.equipment.makerProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }} {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="modelProtocol" [(ngModel)]="data.equipment.modelProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.equipment.model.label' | translate }} {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="serialNumProtocol" [(ngModel)]="data.equipment.serialNumProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }} {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="internalIdProtocol" [(ngModel)]="data.equipment.internalIdProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.equipment.internalId.label' | translate }} {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="locationProtocol" [(ngModel)]="data.equipment.locationProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.equipment.location.label' | translate }} {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
          <mat-select required name="idType" [(ngModel)]="data.equipment.idType">
            <mat-option *ngFor="let item of equipmentTypes" [value]="item.id">
              {{ 'equipmentType.' + item.translation | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!--<div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.equipment.label' | translate }}"
            name="equipment" [(ngModel)]="data.equipment.equipment" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>-->

      <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="data.isDisabled"> 
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.equipment.name" #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
            placeholder="{{ 'protocolEdit.dialog.equipment.name.label' | translate }}"
            (keyup)="lookupEquipment($event)" required>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="onEquipmentChange($event, trigger)">
            <mat-option *ngFor="let item of equipmentAutoComplete; let index = index" [value]="item">
              {{ item.name}} ({{item.serialNum}})
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" *ngIf="data.isDisabled">
        <a class="under-card-link" style="cursor: pointer;margin: inherit;" (click)="createEquipment()">
          {{ 'calibrateEquipmentEdit.general.equipment.notExists' | translate }}
        </a>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.equipment.label' | translate }}" name="name" disabled
            [(ngModel)]="data.equipment.equipment">
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }}" name="maker" disabled
            [(ngModel)]="data.equipment.maker">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.maker.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.model.label' | translate }}" name="model" disabled
            [(ngModel)]="data.equipment.model">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.model.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }}" disabled
            name="serialNum" [(ngModel)]="data.equipment.serialNum">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.serialNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.internalId.label' | translate }}" disabled
            name="internalId" [(ngModel)]="data.equipment.internalId">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.internalId.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.location.label' | translate }}" name="location" disabled
            [(ngModel)]="data.equipment.location" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.location.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="100" *ngIf="requiredReason()">
        <textarea rows="5" matAutosizeMinRows="5" matInput placeholder="{{ 'common.reasonChange' | translate }}"
          [(ngModel)]="data.equipment.reasonChange" name="reasonChange" required style="resize: none;"></textarea>
      </div>
    </div>

    <div fxLayout="column" class="flexwrap" *ngIf="data.equipment.fromProtocol">
      <div fxFlex>
        <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.equipment.showDiffInDeviations">
          {{ 'protocolEdit.dialog.equipment.showDiffInDeviations.label' | translate }}
        </mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.equipment.showDiffInObservations">
          {{ 'protocolEdit.dialog.equipment.showDiffInObservations.label' | translate }}
        </mat-checkbox>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>